<template>
  <a-drawer width="1200" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="屏幕方向" prop="rotationAngle" style="width: 300px;">
            <a-select v-model="form.rotationAngle" placeholder="请选择" allow-clear>
              <a-select-option v-for="(item,key) in [{name:'横屏',key:0},{name:'竖屏',key:1}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="标题字体大小" prop="titleFontSize" style="width: 300px;" v-if="form.rotationAngle == 0">
            <a-input v-model="form.titleFontSize" placeholder="请输入标题字体大小" @change="changeTitleFontSize"/>
          </a-form-model-item>
          <a-form-model-item label="标题颜色" prop="titleColor" style="width: 300px;">
            <a-select v-model="form.titleColor" placeholder="请选择" allow-clear>
              <a-select-option v-for="(item,key) in [{name:'红色',key:1},{name:'黑色',key:2}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="标题粗体" prop="titleBold" style="width: 300px;" v-if="form.rotationAngle == 0">
            <a-select v-model="form.titleBold" placeholder="请选择" allow-clear @change="changeTitleBold">
              <a-select-option v-for="(item,key) in [{name:'正常',key:0},{name:'粗体',key:1}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="标题" prop="title" style="width: 300px;">
            <a-input v-model="form.title" placeholder="请输入标题" @change="changeTitle"/>
          </a-form-model-item>
          <!-- <a-form-model-item label="图片" prop="picPath">
            <div class="ant-upload-preview" @click="$refs.upImg.edit(form.picPath)" >
              <div class="mask" :style="form.picPath ? 'opacity: 0' : 'opacity: 1'">
                <a-icon type="plus" />
              </div>
              <img :src="form.picPath" v-if="form.picPath"/>
            </div>
          </a-form-model-item>
          <div
            id="box1"
            class="mainBox"
            @mousemove="move1"
            @mouseup="moveEnd1"
            :style="{
              width: boxWidth1 + 'px',
              height: boxHeight1 + 'px',
              background: `url(${form.picPath}) 100% 100%`
            }"
          >
            <div
              id="node1"
              @mousedown="moveStart1"
              :style="{
                width: nodeWidth1 + 'px',
                height: nodeHeight1 + 'px',
                left: nodeData1.x + 'px',
                top: nodeData1.y + 'px',
              }"
              class="node"
            >
            </div>
            <div
              id="node"
              ref="node1"
              :style="{
                left: nodeData1.x + 'px',
                top: nodeData1.y + 'px',
              }"
              class="node1"
            >
              <p
                :style="{
                  fontSize: `${form.titleFontSize / 2}px`,
                  fontWeight: `${form.titleBold == 1 ? 'bold' : ''}`,
                  color: `${form.titleColor == 0 ? '#ffffff' : form.titleColor == 1 ? '#ff0000' : form.titleColor == 2 ? '#000000' : ''}`,
                }">{{ form.title }}</p>
              <p
                :style="{
                  fontSize: `${form.contentFontSize / 2}px`,
                  fontWeight: `${form.contentBold == 1 ? 'bold' : ''}`,
                  color: `${form.contentColor == 0 ? '#ffffff' : form.contentColor == 1 ? '#ff0000' : form.contentColor == 2 ? '#000000' : ''}`,
                }">{{ form.content }}</p>
            </div>
          </div> -->
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="内容字体大小" prop="contentFontSize" style="width: 300px;" v-if="form.rotationAngle == 0">
            <a-input v-model="form.contentFontSize" placeholder="请输入内容字体大小" @change="changeContentFontSize"/>
          </a-form-model-item>
          <a-form-model-item label="内容颜色" prop="contentColor" style="width: 300px;">
            <a-select v-model="form.contentColor" placeholder="请选择" allow-clear>
              <a-select-option v-for="(item,key) in [{name:'红色',key:1},{name:'黑色',key:2}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="内容粗体" prop="contentBold" style="width: 300px;" v-if="form.rotationAngle == 0">
            <a-select v-model="form.contentBold" placeholder="请选择" allow-clear @change="changeContentBold">
              <a-select-option v-for="(item,key) in [{name:'正常',key:0},{name:'粗体',key:1}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="内容" prop="content" style="width: 300px;">
            <!-- <a-input v-model="form.content" placeholder="请输入内容" /> -->
            <a-textarea v-model="form.content" :rows="4" placeholder="请输入内容" @change="changeContent"/>
          </a-form-model-item>
          <!-- <div
            id="box2"
            class="mainBox"
            @mousemove="move2"
            @mouseup="moveEnd2"
            :style="{
              width: boxWidth2 + 'px',
              height: boxHeight2 + 'px',
              background: `url(${form.picPath}) 100% 100%`
            }"
          >
            <div
              id="node2"
              @mousedown="moveStart2"
              :style="{
                width: nodeWidth2 + 'px',
                height: nodeHeight2 + 'px',
                left: nodeData2.x + 'px',
                top: nodeData2.y + 'px',
              }"
              class="node"
            >
            </div>
            <div
              id="node2"
              ref="node2"
              :style="{
                left: nodeData2.x + 'px',
                top: nodeData2.y + 'px',
              }"
              class="node1"
            >
              <p
                :style="{
                  fontSize: `${form.titleFontSize / 2}px`,
                  fontWeight: `${form.titleBold == 1 ? 'bold' : ''}`,
                  color: `${form.titleColor == 0 ? '#ffffff' : form.titleColor == 1 ? '#ff0000' : form.titleColor == 2 ? '#000000' : ''}`,
                }">{{ form.title }}</p>
              <p
                :style="{
                  fontSize: `${form.contentFontSize / 2}px`,
                  fontWeight: `${form.contentBold == 1 ? 'bold' : ''}`,
                  color: `${form.contentColor == 0 ? '#ffffff' : form.contentColor == 1 ? '#ff0000' : form.contentColor == 2 ? '#000000' : ''}`,
                }">{{ form.content }}</p>
            </div>
          </div> -->
        </a-col>
      </a-row>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <up-img-modal ref="upImg" :width="800" :height="480" @ok="setImg"/>
  </a-drawer>
</template>

<script>
import { getInformation, addInformation, updateInformation } from '@/api/valve/messageAndPush'
import upImgModal from '@/components/upImgModal'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    upImgModal
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      fileList: [],
      // 表单参数
      form: {
        id: null,
        content: '',
        contentBold: 0,
        contentColor: 2,
        contentFontSize: null,
        rotationAngle: undefined,
        picPath: '',
        title: '',
        titleBold: 0,
        titleColor: 2,
        titleFontSize: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        // titleColor: [
        //   { required: true, message: '请选择标题颜色', trigger: ['blur', 'change'] }
        // ],
        // titleBold: [
        //   { required: true, message: '请选择标题粗体', trigger: ['blur', 'change'] }
        // ],,
        rotationAngle: [
          { required: true, message: '请选择屏幕方向', trigger: ['change,blur'] }
        ],
        titleFontSize: [
          { required: true, message: '标题字体大小不能为空', trigger: 'blur' },
          { validator: this.validateNum, trigger: ['blur'] }
        ],
        // title: [
        //   { required: true, message: '标题不能为空', trigger: 'blur' }
        // ],
        // contentColor: [
        //   { required: true, message: '请选择内容颜色', trigger: ['blur', 'change'] }
        // ],
        // contentBold: [
        //   { required: true, message: '请选择内容粗体', trigger: ['blur', 'change'] }
        // ],
        contentFontSize: [
          { required: true, message: '内容字体大小不能为空', trigger: 'blur' },
          { validator: this.validateNum, trigger: ['blur'] }
        ]
        // content: [
        //   { required: true, message: '内容不能为空', trigger: 'blur' }
        // ]
      },

      status1: null,
      status2: null,
      nodeData1: {
        x: 0,
        y: 0
      },
      nodeData2: {
        x: 0,
        y: 0
      },
      // 移动的起点——鼠标按下时鼠标的坐标
      startX1: 0,
      startY1: 0,

      startX2: 0,
      startY2: 0,
      // 元素的初始位置
      nodeX1: 0,
      nodeY1: 0,

      nodeX2: 0,
      nodeY2: 0,
      // 盒子的初始大小
      boxWidth1: 400,
      boxHeight1: 240,

      boxWidth2: 240,
      boxHeight2: 400,

      // 拖拽盒子大小
      nodeWidth1: 0,
      nodeHeight1: 0,

      nodeWidth2: 0,
      nodeHeight2: 0
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // 开始移动
    moveStart1(e) {
      if (e.target.id) {
        // 开启移动状态
        this.status1 = 'move'
        // 获取鼠标初始坐标
        this.startX1 = e.clientX
        this.startY1 = e.clientY

        // 获取元素的初始位置
        const nodeDOM = document.getElementById('node1')
        this.nodeX1 = nodeDOM.offsetLeft
        this.nodeY1 = nodeDOM.offsetTop
      }
    },
    // 移动中
    move1(e) {
      if (this.status1 === 'move') {
        // 元素的新位置 = 初始位置+（鼠标当前的坐标 - 鼠标初始坐标) 【即鼠标移动的距离】
        this.nodeData1.x = this.nodeX1 + (e.clientX - this.startX1)
        this.nodeData1.y = this.nodeY1 + (e.clientY - this.startY1)

        // 不能从左侧移出盒子
        if (this.nodeData1.x < 0) {
          this.nodeData1.x = 0
        }
        // 不能从右侧移出盒子
        if (this.nodeData1.x > (this.boxWidth1 - this.nodeWidth1)) {
          this.nodeData1.x = this.boxWidth1 - this.nodeWidth1
        }
        // 不能从顶部移出盒子
        if (this.nodeData1.y < 0) {
          this.nodeData1.y = 0
        }
        // 不能从底部移出盒子
        if (this.nodeData1.y > (this.boxHeight1 - this.nodeHeight1)) {
          this.nodeData1.y = this.boxHeight1 - this.nodeHeight1
        }
      }
    },
    // 停止移动
    moveEnd1() {
      // 关闭移动状态
      this.status1 = null
    },
    // 开始移动
    moveStart2(e) {
      if (e.target.id) {
        // 开启移动状态
        this.status2 = 'move'
        // 获取鼠标初始坐标
        this.startX2 = e.clientX
        this.startY2 = e.clientY

        // 获取元素的初始位置
        const nodeDOM = document.getElementById('node2')
        this.nodeX2 = nodeDOM.offsetLeft
        this.nodeY2 = nodeDOM.offsetTop
      }
    },
    // 移动中
    move2(e) {
      if (this.status2 === 'move') {
        // 元素的新位置 = 初始位置+（鼠标当前的坐标 - 鼠标初始坐标) 【即鼠标移动的距离】
        this.nodeData2.x = this.nodeX2 + (e.clientX - this.startX2)
        this.nodeData2.y = this.nodeY2 + (e.clientY - this.startY2)

        // 不能从左侧移出盒子
        if (this.nodeData2.x < 0) {
          this.nodeData2.x = 0
        }
        // 不能从右侧移出盒子
        if (this.nodeData2.x > (this.boxWidth2 - this.nodeWidth2)) {
          this.nodeData2.x = this.boxWidth2 - this.nodeWidth2
        }
        // 不能从顶部移出盒子
        if (this.nodeData2.y < 0) {
          this.nodeData2.y = 0
        }
        // 不能从底部移出盒子
        if (this.nodeData2.y > (this.boxHeight2 - this.nodeHeight2)) {
          this.nodeData2.y = this.boxHeight2 - this.nodeHeight2
        }
      }
    },
    // 停止移动
    moveEnd2() {
      // 关闭移动状态
      this.status2 = null
    },
    changeTitle(e) {
      setTimeout(() => {
        this.nodeWidth1 = this.$refs.node1.offsetWidth
        this.nodeHeight1 = this.$refs.node1.offsetHeight
        this.nodeWidth2 = this.$refs.node2.offsetWidth
        this.nodeHeight2 = this.$refs.node2.offsetHeight
      }, 200)
    },
    changeTitleBold(e) {
      setTimeout(() => {
        this.nodeWidth1 = this.$refs.node1.offsetWidth
        this.nodeHeight1 = this.$refs.node1.offsetHeight
        this.nodeWidth2 = this.$refs.node2.offsetWidth
        this.nodeHeight2 = this.$refs.node2.offsetHeight
      }, 200)
    },
    changeTitleFontSize(e) {
      setTimeout(() => {
        this.nodeWidth1 = this.$refs.node1.offsetWidth
        this.nodeHeight1 = this.$refs.node1.offsetHeight
        this.nodeWidth2 = this.$refs.node2.offsetWidth
        this.nodeHeight2 = this.$refs.node2.offsetHeight
      }, 200)
    },
    changeContent(e) {
      setTimeout(() => {
        this.nodeWidth1 = this.$refs.node1.offsetWidth
        this.nodeHeight1 = this.$refs.node1.offsetHeight
        this.nodeWidth2 = this.$refs.node2.offsetWidth
        this.nodeHeight2 = this.$refs.node2.offsetHeight
      }, 200)
    },
    changeContentBold(e) {
      setTimeout(() => {
        this.nodeWidth1 = this.$refs.node1.offsetWidth
        this.nodeHeight1 = this.$refs.node1.offsetHeight
        this.nodeWidth2 = this.$refs.node2.offsetWidth
        this.nodeHeight2 = this.$refs.node2.offsetHeight
      }, 200)
    },
    changeContentFontSize(e) {
      setTimeout(() => {
        this.nodeWidth1 = this.$refs.node1.offsetWidth
        this.nodeHeight1 = this.$refs.node1.offsetHeight
        this.nodeWidth2 = this.$refs.node2.offsetWidth
        this.nodeHeight2 = this.$refs.node2.offsetHeight
      }, 200)
    },
    validateNum (rule, value, callback) {
      // console.log(rule, value)
      if (!/^(?:[0-9]\d*)$/.test(value)) {
        callback(new Error('请输入正整数'))
      } else {
        callback()
      }
    },
    setImg (url) {
      console.log(url)
      this.form.picPath = url
      this.form.picPath = url
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        content: '',
        contentBold: 0,
        contentColor: 2,
        contentFontSize: null,
        rotationAngle: undefined,
        picPath: '',
        title: '',
        titleBold: 0,
        titleColor: 2,
        titleFontSize: null
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getInformation({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.fileList = [{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.picPath
        }]

        setTimeout(() => {
          this.nodeWidth1 = this.$refs.node1.offsetWidth
          this.nodeHeight1 = this.$refs.node1.offsetHeight
          this.nodeWidth2 = this.$refs.node2.offsetWidth
          this.nodeHeight2 = this.$refs.node2.offsetHeight
        }, 200)
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateInformation(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addInformation(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .mainBox {
    position: relative;
    margin-bottom: 30px;
  }
  .node {
    position: absolute;
    z-index: 2;
    cursor: move;
    background: transparent;
  }
  .node1 {
    position: absolute;
    z-index: 1;
    cursor: move;
    background: transparent;
    p{
      user-select: none;
      margin: 0 !important;
    }
  }
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
